import React from 'react';
import ProductImg from "../Assets/images/meet.jpg";
import ProductImg1 from "../Assets/images/meet1.jpg";
import ProductImg2 from "../Assets/images/meet2.jpg";
import ProductImg3 from "../Assets/images/meet3.jpg";
import ProductImg4 from "../Assets/images/meet4.jpg";
import ProductImg5 from "../Assets/images/meet5.jpg";
import ProductImg6 from "../Assets/images/meet6.jpg";
import ProductImg7 from "../Assets/images/meet7.jpg";
import ProductImg8 from "../Assets/images/meet8.jpg";
import ProductImg9 from "../Assets/images/meet9.jpg";
import ProductImg10 from "../Assets/images/meet10.jpg";

const products = [
  {
    id: 1,
    image: ProductImg,
    title: 'SHRIMP',
    description: 'This bag contains frozen shrimp, one of the most widely consumed types of seafood. Shrimp are rich in protein and low in calories, making them a healthy choice. They are versatile and can be used in numerous dishes, from pastas and stir-fries to salads and tacos.',
  },
  {
    id: 2,
    image: ProductImg1,
    title: 'FISH FILLET',
    description: 'This is a white fish fillet, typically used in various cuisines around the world. Fillets are boneless cuts of fish, ideal for grilling, baking, or frying. They offer a mild flavor and tender texture, making them a popular choice for many recipes.',
  },
  {
    id: 3,
    image: ProductImg2,
    title: 'Salmon Fillet',
    description: 'This is a fresh, boneless fillet of salmon, showcasing the vibrant orange color and distinct texture of salmon meat. It is often used in recipes like grilled salmon, sushi, or baked salmon.',
  },
  {
    id: 4,
    image: ProductImg3,
    title: 'SQUID',
    description: 'This is a whole squid, a common seafood choice known for its mild, slightly sweet taste and chewy texture. Squid can be prepared in various ways, including grilling, frying, or as a part of soups and stews. It’s often enjoyed in dishes like calamari.',
  },
  {
    id: 5,
    image: ProductImg4,
    title: 'Surimi Crab Sticks',
    description: 'This Gadre Surimi Crab Sticks package contains ready-to-eat, imitation crab sticks made from fish paste, mimicking the taste and texture of real crab. Ideal for quick salads, sushi, or snacking, this 1 kg pack includes about 64 pieces, offering a high-protein, hassle-free cooking option.',
  },
  {
    id: 6,
    image: ProductImg5,
    title: 'Raw Meat with Ribs',
    description: 'This cut of raw meat with ribs, possibly pork or beef, is great for barbecuing or roasting. When cooked slowly, the meat becomes tender and flavorful. Ribs are often marinated or seasoned with spices to enhance their taste, making them a favorite for grilling.',
  },
  {
    id: 7,
    image: ProductImg6,
    title: 'Frozen Shrimp',
    description: 'This is a vacuum-sealed bag of frozen, raw shrimp with tails on. Such shrimp are typically used for various seafood dishes and require thawing before cooking.',
  },
  {
    id: 8,
    image: ProductImg7,
    title: 'Packaged Bacon',
    description: 'This package contains slices of bacon. Bacon is a popular pork product, often used for breakfast or as an ingredient to add flavor to various dishes.',
  },
  {
    id: 9,
    image: ProductImg8,
    title: 'Abhi pta nahi hai',
    description: 'An excellent addition to your lifestyle.',
  },
  {
    id: 10,
    image: ProductImg9,
    title: 'Frozen Ground Meat Package',
    description: 'This package contains frozen ground meat, ideal for making dishes like burgers, meatballs, and pasta sauces. Freezing helps maintain freshness and extend the meat’s shelf life. Thawing before cooking is recommended to preserve flavor and texture.',
  },
  {
    id: 11,
    image: ProductImg10,
    title: 'Edamame Pods',
    description: 'Edamame, or young soybeans, are a nutritious snack, often served boiled or steamed with a sprinkle of salt. They’re high in protein and fiber, making them a healthy choice. Edamame can also be added to salads and stir-fries for extra texture and nutrition.',
  },
];

const Product = () => {
  return (
    <section className='menu' id="menu">
      <h1 className='heading'>
        Our <span>Products</span>
      </h1>
      <div className='box-container'>
        {products.map(product => (
          <div className='box' key={product.id}>
            <img src={product.image} alt={product.title} />
            <h2>{product.title}</h2>
            <p>{product.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Product;

import React, {useRef} from 'react'
import Logo from "../Assets/images/logo.avif";
const Navbar = () => {
    const searchRef =useRef();
    const searchHandler=()=>{

     searchRef.current.classList.toggle("active");
    };
  return <>
  <header className="header">
     <a href="#" className='logo'>
        <img src={Logo} alt=""/>
     </a>
     <nav className="navbar">
        <a href="#home">Home</a>
        <a href="#about">About Us</a>
        <a href="#product">Product</a>
        <a href="#contact">Contact</a>
     </nav>
     <div className="icons">
          <div className="fas fa-search" onClick={searchHandler}></div>
          <div className="fas fa-shopping-cart"></div>
          <div className="fas ba-bars" id="menu=btn"></div>
          </div>
          <div className="search-form" ref={searchRef}>
            <input type="search" placeholder="search here..." id="search-box"/>
            <label htmlFor="search-box" className="fas fa-search"></label>
          </div>
  </header>
  </>
  
};

export default Navbar

import React from 'react';

const Content = () => {
  const phoneNumber = "9696292481";
  const email = "example@example.com";
  const location = "123, Example Street, City, Country";

  const handleContactClick = () => {
    const whatsappURL = `https://wa.me/${phoneNumber}`;
    window.open(whatsappURL, '_blank'); // Opens WhatsApp in a new tab
  };

  return (
    <>
      <section className='contact' id="contact">
        <h1 className='heading'>
          <span>Contact</span> Us
        </h1>
        <div className='row'>
          <div className='info'>
            <h3>Our Location</h3>
            <p>{location}</p>

            <h3>Email</h3>
            <p>{email}</p>

            <h3>Phone</h3>
            <p className='phone-link'>{phoneNumber}</p>

            <button onClick={handleContactClick} className='btn'>Contact Now</button>
          </div>
        </div>
      </section>
    </>
  );
}

export default Content;

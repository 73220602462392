import React from 'react'
import AboutImg from "../Assets/images/about.avif";
const About = () => {
  return (
    <>
      <section className="about" id="about">
        <h1 className="heading">
            <span>about </span>Us
        </h1>
        <div className='row'>
            <div className='img'>
                 <img src={AboutImg} alt=''/>
            </div>
            <div className='content'>
                <h3>
                What makes our food special? 
                </h3>
                <p>
                What makes our food special is our commitment to using fresh, high-quality ingredients and time-honored cooking techniques that enhance every dish. We celebrate a diverse range of flavors and culinary traditions, blending them to create unique recipes that inspire creativity in the kitchen. 
                Each meal is crafted with love and passion, ensuring that every bite not only satisfies your hunger but also delights your senses. Our goal is to transform ordinary moments into extraordinary experiences, bringing friends and family together around the table.
                </p>
            </div>
        </div>
              </section>
    </>
  )
}

export default About

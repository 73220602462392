import React from 'react';
import"./Assets/css/style.css";
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Product from './components/Product';
import Contact from './components/Contact';
import Footer from './components/Footer';

const App = () =>{
    return <>
    <Navbar/>
    <Home/>
    <About/>
    <Product/>
    <Contact/>
    <Footer/>
    </>
} 
export default App;

import React from 'react'

const Home = () => {
  return (
    <>
      <section className="home" id="home">
        <div className="content">
            <h3>
                Fresh food 
            </h3>
            <p>Welcome to our food website, where culinary delights come to life! Discover a world of flavors with our expertly crafted recipes, restaurant reviews, and cooking tips.
             Whether you're a seasoned chef or a curious beginner, we inspire you to create unforgettable meals and share the joy of food with friends and family.</p>
        </div>

      </section>
    </>
  )
}

export default Home

import React from 'react';
import facebookIcon from '../Assets/images/facebook.jpg'; 
import twitterIcon from '../Assets/images/twitter.png';   
import instagramIcon from '../Assets/images/instagram.png'; 
import linkedinIcon from '../Assets/images/linkedin.jpg'; 
import pinterestIcon from '../Assets/images/pinterest.jpg'; 

const Footer = () => {
  return (
    <section className='footer'>
      <div className="share">
        <a href="#"><img src={facebookIcon} alt="Facebook" /></a>
        <a href="#"><img src={twitterIcon} alt="Twitter" /></a>
        <a href="#"><img src={instagramIcon} alt="Instagram" /></a>
        <a href="#"><img src={linkedinIcon} alt="LinkedIn" /></a>
        <a href="#"><img src={pinterestIcon} alt="Pinterest" /></a>
      </div>
      <div className='links'>
        <a href="#">Home</a>
        <a href="#">About Us</a>
        <a href="#">Product</a>
        <a href="#">Contact</a>
      </div>
      <div className='credit'>
        Created by <span>Atrodious Technologies </span>
      </div>
    </section>
  );
}

export default Footer;
